<template>
  <div class="row">
    <div class="col-12">
        اختر طريقة التحضير:
        <br><br>
    </div>
    <div class="col-12 col-lg-3" v-if="checkPer('teachers|scan|bulk')">
        <div class="card card-body text-center item" @click="$router.push('/_teachers/scan/bulk')">
            <img :src="require('@/assets/images/users.png')" class="c">
            <h5>
                تحضير جماعي
            </h5>
        </div>
    </div>
    <div class="col-12 col-lg-3" v-if="checkPer('teachers|scan|manual')">
        <div class="card card-body text-center item" @click="$router.push('/_teachers/scan/manual')">
            <img :src="require('@/assets/images/manual.png')" class="c">
            <h5>
                تحضير يدوي
            </h5>
        </div>
    </div>
    <div class="col-12 col-lg-3" v-if="checkPer('teachers|scan|barcode')">
        <div class="card card-body text-center item" @click="$router.push('/_teachers/scan/manual?barcode')">
            <img :src="require('@/assets/images/barcode.png')" class="c">
            <h5>
                تحضير بالباركود
            </h5>
        </div>
    </div>
    <div class="col-12 col-lg-3"  v-if="checkPer('devices')">
        <div class="card card-body text-center item" @click="$router.push('/_teachers/scan/fingerprint')">
            <img :src="require('@/assets/images/fingerprint.png')" class="c">
            <h5>
                تحضير باجهزة البصمة
            </h5>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    created(){
      if(!checkPer("teachers|scan")){
          this.$router.push('/NotPermitted')
          return false;
      }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
}
.item img{
    width: 100px
}
</style>